var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({ height: '100%' })},[_c('v-card',[_c('v-card-title',[_vm._v(" Submissions "),_c('v-spacer'),(_vm.isTeacherOrAdmin)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search username (Exact match)","hint":"Press enter to search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.option.username = _vm.search || null}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"error"},on:{"click":_vm.getSubmissions}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Submissions")])])],1),_c('v-card-title',[(_vm.isTeacherOrAdmin)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"items":['My Submissions', 'All Submissions'],"solo":"","hide-details":""},on:{"change":_vm.getSubmissions},model:{value:(_vm.whosSubm),callback:function ($$v) {_vm.whosSubm=$$v},expression:"whosSubm"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-combobox',{attrs:{"label":"Problem","items":_vm.problems,"clearable":"","solo":"","hide-details":""},model:{value:(_vm.option.selectedProblem),callback:function ($$v) {_vm.$set(_vm.option, "selectedProblem", $$v)},expression:"option.selectedProblem"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.submStatus,"clearable":"","solo":"","hide-details":""},model:{value:(_vm.option.selectedStatus),callback:function ($$v) {_vm.$set(_vm.option, "selectedStatus", $$v)},expression:"option.selectedStatus"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"label":"Language","items":_vm.LANG,"solo":"","hide-details":"","multiple":""},model:{value:(_vm.option.selectedLanguage),callback:function ($$v) {_vm.$set(_vm.option, "selectedLanguage", $$v)},expression:"option.selectedLanguage"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.option.page,"items-per-page":_vm.option.itemsPerPage,"server-items-length":_vm.submissionCount,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [5, 10, 20, 30]
      }},on:{"update:page":[function($event){return _vm.$set(_vm.option, "page", $event)},function (val) { return _vm.option.page = val; }],"update:items-per-page":function (val) { return _vm.option.itemsPerPage = val; }},scopedSlots:_vm._u([{key:"item.submissionId",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":'/submission/'+item.submissionId,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v(_vm._s(item.submissionId.substr(-6)))]),_c('ui-button',{staticClass:"copy-code ml-2",attrs:{"id":item.submissionId,"color":"gray","icon":"","x-small":""}},[_c('template',{slot:"content"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],2)]}},{key:"item.problemId",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"subtitle-1",attrs:{"href":'/problem/'+item.problemId,"rel":"noopener noreferrer","target":"_blank"}},'a',attrs,false),on),[_vm._v(_vm._s(item.problemId))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.pid2Pname[("" + (item.problemId))]))])])]}},{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"subtitle-1"},'span',attrs,false),on),[_vm._v(_vm._s(item.user.username))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.user.displayedName))])])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',{style:({ color: _vm.SUBMISSION_COLOR[("" + (item.status))] })},[_vm._v(" "+_vm._s(_vm.SUBMISSION_STATUS[item.status])+" ")])]}},{key:"footer.prepend",fn:function(){return [_c('v-text-field',{staticClass:"px-4",attrs:{"value":_vm.option.page,"label":"Page","type":"number","min":"1","max":Math.floor(_vm.submissionCount / _vm.option.itemsPerPage) + 1},on:{"input":function($event){_vm.option.page = parseInt($event, 10)}}})]},proxy:true}],null,true)})],1),_c('v-snackbar',{attrs:{"color":"info"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.alertMsg))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }